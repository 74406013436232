@font-face {
  font-family: 'Sinthya';
  src: url('Sinthya-2.ttf') format('truetype');
}

@font-face {
  font-family: 'MiSans-Bold';
  src: url('MiSans-Bold-Processed.ttf') format('truetype');
}

@font-face {
  font-family: 'MiSans-Normal';
  src: url('MiSans-Normal-Processed.ttf') format('truetype');
}

* {
  box-sizing: border-box;
}

/* 根容器样式 */
.App {
    /* 文本居中对齐 */
    text-align: center;
    /* 最小高度设置为视口高度，确保元素至少占据整个视口的高度 */
    min-height: 100vh;
    /* 背景颜色设置为黑色 */
    background-color: #1a1a1a;
    /* 主轴方向设置为水平方向 */
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: MiSans-Normal;
    /* 动态计算字体大小，以适应不同屏幕大小 */
    font-size: calc(10px + 2vmin);
    /* 文本颜色设置为d1d1d1 */
    color:#d1d1d1;
}

.Container {
  /*最大宽度1432*/
  max-width: 1432px;
  /*当屏幕宽度*0.78 < 1432时展示为78%*/
  width: 78%;
  margin: 13.88vh 0 0 0;
  display: flex;
}

.SideBar {
  width: 22.84%;
  margin: 1.09vh 0 0 0;
  text-align: left;
  font-weight: 600;
  font-size: 20px;
  .Logo {
    font-family: Sinthya;
    font-size: 60px;
    font-weight: normal;
  }
  .Tag {
    margin-top: 78px;
    ul {
      li {
        margin-bottom: 11px;
        line-height: 34.48px;
      }
      li:hover {
        font-family: MiSans-Bold;
        color: white;
      }
      .selected {
        font-family: MiSans-Bold;
        color: white;
      }
      list-style-type: none;
      padding: 0;
    }
  }
}

.AboutMe {
  margin-top: 122px;
  ul {
    li {
      display: inline;
      margin-right: 18px;
      img {
        width: 28px;
        height: auto;
      }
    }
    list-style-type: none;
    padding: 0;
  }
}

.Album {
  width: 77.16%;
  padding: 0 8.03% 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  img {
    width: 100%;
    margin: 0.51%;
    width: 32.31%;
  }
}

.Footer {
  width: 98.98%;
  margin: auto;
  margin-top: 120px;
  margin-bottom: 20px;
  padding: 28px 0 0 0;
  justify-self: center;
  border-top: 1px solid white;
  font-size: 10px;
  line-height: 13.26px;
  font-weight: 250;
  text-align: left;
  /* 设置链接的字体颜色为蓝色 */
  a {
    color: #d1d1d1;
    text-decoration: none; /* 删除下划线 */
  }

  /* 鼠标悬停时改变链接的颜色 */
  a:hover {
    color: white;
  }
}

/*平板端样式*/
@media only screen and (max-width: 1024px) {
  .App {
    /* 背景颜色设置为黑色 */
    background-color: rgb(62, 132, 231);
  } 
}

/*手机端样式*/
@media only screen and (max-width: 600px) {
  .App {
    /* 背景颜色设置为黑色 */
    background-color: rgb(231, 62, 62);
  } 
}